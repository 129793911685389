import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {

  constructor(private sessionService: AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     
    if (req.url.includes("assets/") || req.url.includes("/cliente") || req.url.includes("/venta-rapida")) {
      // console.log("public", req);
      return next.handle(req);
    }

    return this.sessionService.getCurrentUser().pipe(
      switchMap(jwtToken => {
        // console.log(`${jwtToken.signInUserSession.idToken.jwtToken}`);
        const authReq = req.clone({
          headers: req.headers.set('Authorization', `${jwtToken.signInUserSession.idToken.jwtToken}`)
        });
        console.log(authReq);
        return next.handle(authReq);
      })
    );
  }
}
