import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth/auth.service';
import { CrudService } from './crud.service';
import { IProfileDto } from './Models/profile';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class SesionService extends CrudService<any> {
  public infoSession: Subject<any> = new Subject<any>();
  constructor(
    private config: AppConfigService,
    http: HttpClient,
    private _auth: AuthService,
    private _shared: SharedService) {
    super(config.SessionUrl, http);

  }
  firstTimeSesion(data: any) {
    let url = `${this.endpoint}`
    return this.http.post<any>(url, data);
  }
  getDatosSesion() {
    let url = `${this.endpoint}`;
    return this.http.get<any>(url);
  }
  saveInfo(data) {
    let url = `${this.endpoint}/SaveInfo`;
    return this.http.post<any>(url, data);
  }
  saveImageProfile(data) {
    let url = `${this.endpoint}/SaveImage`;
    return this.http.post<any>(url, data);
  }
  saveWeb(data) {
    let url = `${this.endpoint}/SaveWeb`;
    return this.http.post<any>(url, data);
  }
  async addUserNuevoSesion() {
    let cognito = (await this._auth.getCurrentPoolUser());
    let data2 = cognito.attributes;
    let ap: string = data2["custom:apellidos"];
    let apm: string = "", apt: string = "";
    if (!this._shared.isNullOrUndefined(ap)) {
      let apl = ap.split(" ");
      apt = apl[0];

      if (apl.length > 0) {
        apm = apl[1];
      }
    }
    let nambre: string = "";
    if (!this._shared.isNullOrUndefined(data2.name)) {
      nambre = data2.name;
    }
    else {
      nambre = data2.email;
    }

    this._shared.datosUser = {
      nombre: nambre,
      apellidoPaterno: apt,
      apellidoMaterno: apm,
      imagen: "",
      correo: data2.email,
      telefono: data2["custom:tel"]
    };
    //  ;
    if (localStorage.getItem(`usr${cognito.username}`) !== null) {
      // this.obtenerSoloFoto();
      this.obtenerDatosSesion();
      return false;
    }
    let info = {
      id: cognito.username,
      correo: data2.email,
      nombre: nambre,
      apellidoPaterno: apt,
      apellidoMaterno: apm,
      nombreUsuario: nambre,
      telefono: data2["custom:tel"]
    };
    this._shared.cargando = true;
    this.firstTimeSesion(info).subscribe({
      next: data => {
        localStorage.setItem(`usr${cognito.username}`, "true");
        this.obtenerDatosSesion();
        this._shared.cargando = false;
      },
      error: error => {
        console.log(error);
        this._shared.cargando = false;
      }
    });
  }
  private obtenerDatosSesion() {
    this.getDatosSesion().subscribe({
      next: data => {
        this._shared.datosUser = {
          apellidoMaterno: this._shared.isNullOrUndefined(data.apellidoMaterno, true) ? "" : data.apellidoMaterno,
          apellidoPaterno: data.apellidoPaterno,
          correo: data.correo,
          correoAdicional: data.correoAdicional,
          destinoPlaceId: data.destinoPlaceId === "null" ? null : data.destinoPlaceId,
          destinoPred: data.destinoPred === "null" ? null : data.destinoPred,
          estatus: data.estatus,
          fechaCreacion: data.fechaCreacion,
          id: data.id,
          imagen: data.imagen,
          imagenS3Link: data.imagenS3Link,
          imagenS3MinLink: data.imagenS3MinLink,
          nombre: data.nombre,
          nombreUsuario: data.nombreUsuario,
          origenPlaceId: data.origenPlaceId === "null" ? null : data.origenPlaceId,
          origenPred: data.origenPred === "null" ? null : data.origenPred,
          rolId: data.rolId,
          telefono: data.telefono,
          rfc: data.rfc,
          ubicacionId: data.ubicacionId
        };
        this.infoSession.next(true);
      }, error: data => {
        console.log(data);
      }
    });
  }
  getOnlyPhoto(): Observable<any> {
    let url = `${this.endpoint}/OnlyPhoto`
    return this.http.get<any>(url);
  }
  obtenerSoloFoto() {
    this.getOnlyPhoto().subscribe({
      next: data => {
        console.log(data);
        if (!this._shared.isNullOrUndefined(data, true)) {
          this._shared.datosUser.imagen = data.ruta;
        }
      }, error: data => {
        console.log(data);
      }
    });
  }
  updateProfileTel(data) {
    let url = `${this.endpoint}/UpdatePhone`;
    return this.http.put<any>(url, data);
  }
}
